@import "./animations";
@import "./mixins";
@import "./variables";
@import "./overrides";

#root {
  cursor: url("data:image/svg+xml,%3Csvg version='1.1' class='drop' id='normal_cursor' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='45px' height='45px' viewBox='0 0 512 512' style='color: blue;' xml:space='preserve'%3E %3Cpath d='M 0 0 L 55 170 A 1 1 0 0 0 168 62 L 0 0' stroke='blue' stroke-width='2px' fill='rgb(204,222,254)' /%3E %3C/svg%3E"),
    pointer;
  body {
    overflow: hidden;
    min-height: 100%;
  }
  #introduction {
    flex-direction: column;
    height: 100vh;
    min-height: 800px;
    .introduction-container {
      .content {
        margin: 3rem auto 2rem auto;
        .title {
          color: var(--foreground-primary);
        }
      }
    }
  }
  .name-description {
    font-size: 20px;
    white-space: pre-wrap;
  }

  #about {
    .about-container {
      width: 80vw;
      display: block;
      align-items: center;
      justify-content: center;
      .about-description {
        color: var(--foreground-primary);
        font-size: 18px;
        font-weight: normal;
        white-space: pre-wrap;
        text-align: justify;
      }

      .tech-stack {
        span,
        img,
        svg,
        i {
          display: inline-block;
          vertical-align: middle;
        }

        svg,
        i {
          width: 30px;
          height: 36px;
          padding: 4px;

          display: inline-block;
          vertical-align: middle;
        }
        svg *,
        i * {
          width: 30px;
          height: 36px;
        }
      }
    }
  }

  #projects {
    background-color: var(--background-primary);
    // padding: 6rem 1.5rem;
    // transform: skewY(2deg);
    .projects-container {
      display: flex;
      flex-direction: column;
      // transform: skewY(-2deg);
      min-height: 30vh;
      h1 {
        color: inherit !important;
        font-size: 36px;
        font-weight: bold;
        align-items: center;
        margin-bottom: 1rem;
      }
      h2 {
        color: inherit !important;
        font-size: 24px;
        font-weight: bold;

        align-items: center;
        margin-bottom: 1rem;
      }
    }

    .project-sections {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      gap: 5rem;
      padding: 1.5em 0 1.5em 0;
    }
    .snaps {
      scroll-snap-type: x mandatory;
    }
    .snaps > * {
      scroll-snap-align: start;
    }
    .project-sections::-webkit-scrollbar {
      display: block !important;
      width: 5px;
    }
    .project-sections::-webkit-scrollbar-track {
      background: var(--background-secondary-alt);
    }
    .project-sections::-webkit-scrollbar-thumb {
      background: var(--lighter);
      border-radius: 8px;
    }
    .project-sections::-webkit-scrollbar-thumb:hover {
      background: white;
    }

    .project-card {
      background-color: var(--background-secondary);
      border: 1px solid var(--background-secondary-alt); //#aaa;
      border-radius: 12px;
      padding: 16px;
      margin: 1em 2em 1em 2em;
      text-align: left;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      min-width: 80vw;
      // min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .project-card.expanded {
      min-height: 200px;
    }

    .project-content {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      flex-grow: 1;
    }

    .project-image {
      flex-shrink: 0;
      width: 80vw;
      max-width: 100%;
      transition: all 0.7s ease;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      margin: 0;
      border-radius: 10px;
      cursor: url("../../public/assets/files/hand.svg"), pointer;
    }

    .project-card:hover {
      border: 1px solid var(--background-secondary-altlight);
      // transform: translateY(-2px);
      // box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    }

    .project-name {
      font-size: 1.2em;
      margin-bottom: 12px;
      color: var(--lighter);
      font-weight: bold;
    }

    .project-card.expanded .project-image {
      width: 70%;
    }

    .project-description {
      flex: 1;
      opacity: 0;
    }

    .project-stack {
      opacity: 0;
      margin: 0.5em;
      transition: opacity 1s ease;
    }

    .project-card.expanded .project-description {
      opacity: 1;
    }
    .project-card.expanded .project-stack {
      opacity: 1;
    }

    .project-buttons {
      display: flex;
      justify-content: left;
      gap: 0.6em;
      margin: 0;
    }

    .button {
      text-decoration: none;
      padding: 8px 16px;
      font-size: 1em;
      border-radius: 6px;
      color: white;
      background-color: var(--background-secondary-altlight);
      transition: background-color 0.2s ease;
    }

    // .button:hover {
    //   background-color: #0056b3;
    // }

    .github-button:hover {
      background-color: #5a6268;
    }

    .demo-button {
      background-color: var(--accgreen);
    }

    .demo-button:hover {
      background-color: #218838;
    }
  }

  #blogging {
    background-color: var(--background-primary);
    padding: 8rem 1rem;

    .blogging-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        color: var(--foreground-primary);
        font-size: 36px;
        font-weight: bold;

        align-items: center;
        margin-bottom: 1rem;
      }
    }
  }

  .nomatch {
    background-color: var(--background-primary) !important;
    padding: 6rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      color: #6fb0fbc4 !important;
      font-size: 36px;
      font-weight: bold;

      align-items: center;
      margin-bottom: 1rem;
    }
  }

  .playback {
    position: inherit;
    background-color: var(--background-primary) !important;
    height: 100vh;
    margin-top: 0;

    // padding: 6rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 32px;
      font-weight: bold;
      align-items: center;
      margin-bottom: 0;
    }
    h2 {
      font-size: 30px;
      font-weight: bold;
      align-items: center;
      margin-bottom: 1rem;
    }
    #dark h1 {
      color: #083d79 !important;
    }
    #light h1 {
      color: #0049f4 !important;
    }
    #dark h2 {
      color: #15447a !important;
    }
    #light h2 {
      color: #0049f4 !important;
    }
  }
}

.resources-page {
  background-color: var(--background-primary);
  color: var(--text-primary);
  padding: 0.5em;
  min-height: 100vh;
}

.resources-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.resources-header h1 {
  font-size: 2.5rem;
  color: var(--accent);
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 2px;
}

.resources-search {
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--accent);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  outline: none;
  margin-top: 20px;
  transition: border-color 0.6s ease;
}

.resources-search:focus {
  border-color: var(--lighter);
}

/* Grid layout */
.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1em;
  margin: 1em;
}

/* Resource card styling */
.resource-card {
  margin-top: 20px;
  background-color: var(--background-primary);
  border: 1px solid var(--background-secondary-alt); //#aaa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
}

.resource-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 80px 2px var(--background-secondary-alt);
}

.resource-image-wrapper {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.resource-image {
  opacity: 1;
  transition: opacity 0.3s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.resource-card:hover .resource-image {
  opacity: 1;
}

.resource-content {
  padding: 1rem;
}

.resource-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--lighter);
}

.resource-description {
  font-size: 0.9rem;
  color: var(--greylight);
  margin-bottom: 0.5rem;
}

.resource-category {
  display: inline-block;
  background-color: var(--accent);
  color: var(--background-primary);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.resource-link {
  display: block;
  text-decoration: none;
  color: var(--accent);
  font-weight: bold;
  margin-top: 0.5rem;
}

.resource-link:hover {
  text-decoration: underline;
}

.tools-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  min-height: 100vh;
  background-color: var(--background-primary);
}

.tools-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.2rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.tool-card {
  margin: 0;
  background-color: var(--background-primary);
  border: 1px solid var(--background-secondary-alt); //#aaa;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  transition: transform 1s ease, box-shadow 1s ease, grid-area 1s ease;
  width: 100%;
  box-sizing: border-box;
}

.tool-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 80px 2px var(--background-secondary-alt);
}

.tool-card img {
  width: auto;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  transition: all 1s ease;
}

.tool-card.expanded {
  grid-column: 1 / span 3;
  grid-row: 1;
  min-height: 50vh;
  transform: translate(0, 0);
  box-shadow: 0 8px 80px 2px var(--background-secondary-alt);
}

.tool-card.expanded img {
  max-width: 80vw;
  height: auto;
  margin: 1rem auto;
}

.tool-card .tool-content {
  margin-top: 1em;
  color: var(--lighter);
}

.tool-name {
  font-size: 1.2em;
  font-weight: bold;
}

.tool-description,
.tool-setup,
.tool-category {
  margin: 0.5em 0;
}

.tool-link {
  display: block;
  margin: 0.5em 0.8em 0 0;
  color: #007bff;
  text-decoration: none;
  max-width: 100px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .resources-grid {
    grid-template-columns: 1fr;
  }
  .resource-image {
    height: 100px;
  }
  .tools-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .tool-card.expanded {
    grid-column: 1;
    grid-row: 1;
    min-height: 60vh;
  }
}

:root::-webkit-scrollbar {
  display: none;
}
